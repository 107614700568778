// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CommentData } from "./CommentsController.web";

export const configJSON = require("./config");

export interface Props {
  classes?: any
  comment: CommentData
  onLikeComment: (commentId: string, isLike: boolean) => void
}

interface S {
}

interface SS {
}

export default class CommentItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}
// Customizable Area End