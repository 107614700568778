// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import _ from 'lodash'
import { sendAPIRequest } from "../../../components/src/utils";

interface IGif {
  id: string
  name: string
  url: string
  downsizeUrl: string
}

export interface Props {
  checked?: boolean
  onSelectGif: (gifUrl: string, gifName: string) => void
}

interface S {
  token: string
  showGifModal: boolean
  searchGif: string
  gifList: Array<IGif>
  loadingStates: { [key: string]: boolean };
}

interface SS {
  id: any;
}

export default class GifController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGifId: string = ''
  getTrendingGifId: string = ''

  private debouncedFetchGif: (query: string) => void

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: '',
      showGifModal: false,
      searchGif: '',
      gifList: [],
      loadingStates: {},
    };
    this.debouncedFetchGif = _.debounce(this.callAPIGetGifList, 300)
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken') || ''
    this.setState({ token: token })
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getGifId]: this.handleGetGifResopnse,
      [this.getTrendingGifId]: this.handleGetGifResopnse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleGetGifResopnse = (responseJson: any) => {
    const gifs: IGif[] = responseJson?.data?.map((gif: any) => {
      return {
        id: gif?.id,
        name: gif?.title,
        url: gif?.images?.downsized?.url || gif?.images?.original?.url,
        downsizeUrl: gif?.images?.fixed_width?.url,
      }
    })
    this.setState({ gifList: gifs })
  }

  handleClickOpenGifModal = () => {
    this.getTrendingGif()
    this.setState({ showGifModal: true })
  }

  handleClickCloseGifModal = () => this.setState({ showGifModal: false, searchGif: '' })

  handleChangeSearchGif = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value
    this.setState({ searchGif: search })
    if (!!search.trim()) {
      this.debouncedFetchGif(search)
    }
  }

  callAPIGetGifList = (search: string) => {
    this.getGifId = sendAPIRequest(
      `https://api.giphy.com/v1/gifs/search?api_key=NSXfeMGFbp8aa3HYXqoZ3V8z0kRhDS8R&q=${search}&limit=20&offset=0&rating=g&lang=en&bundle=messaging_non_clips`,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
  }

  getTrendingGif = () => {
    this.getTrendingGifId = sendAPIRequest(
      `https://api.giphy.com/v1/gifs/trending?api_key=NSXfeMGFbp8aa3HYXqoZ3V8z0kRhDS8R&limit=20`,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
  }

  handleImageLoaded = (id: string) => {
    this.setState(prevState => ({
      loadingStates: {
        ...prevState.loadingStates,
        [id]: false
      },
    }));
  };
}
// Customizable Area End
