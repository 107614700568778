// Customizable Area Start
import React from "react";
import moment from "moment";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";

import CommentItemController, {
  Props,
} from "./CommentItemController.web";
import { favActive, favIcon, profileLogo } from "../../activityfeed/src/assests";
import { replyIcon } from "./assets";

export class CommentItem extends CommentItemController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, comment, onLikeComment } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.userInfoContainer}>
            <img
              src={comment.attributes.comment_by.profile_photo || profileLogo}
              className={classes.profilePhoto}
              alt="profile-photo"
            />
            <Box className={classes.userNameWrapper}>
              <Typography className={classes.userNameText}>{comment.attributes.comment_by.full_name}</Typography>
              <Typography className={classes.createdAtText}>{moment(comment.attributes.created_at).fromNow()}</Typography>
            </Box>
          </Box>
          <Box className={classes.actionWrapper}>
            <img
              data-testid="like-comment-button"
              src={comment.attributes.is_liked ? favActive : favIcon}
              className={classes.likeButton}
              alt="like-button"
              onClick={() => onLikeComment(comment.id, !comment.attributes.is_liked)}
            />
            <Typography className={classes.totalLikeText}>{comment.attributes.total_likes}</Typography>
          </Box>
        </Box>
        <Typography className={classes.commentText}>{comment.attributes.body}</Typography>
        <Box className={classes.replyContainer}>
          <img src={replyIcon} width={20} height={20} alt="reply-icon" />
          <Typography className={classes.replyText}>Reply</Typography>
        </Box>
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 0 20px 10px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfoContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  profilePhoto: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
  },
  userNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  userNameText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFF',
  },
  createdAtText: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    color: '#FFF',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'center',
  },
  likeButton: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  totalLikeText: {
    color: '#6B6B6B',
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
  },
  commentText: {
    fontFamily: 'Manrope',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 400,
  },
  replyContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  replyText: {
    fontFamily: 'Manrope',
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 500,
  },
});

export default withStyles(styles)(CommentItem);
// Customizable Area End