import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:boolean;
  // Customizable Area End
}

interface S {
   // Customizable Area Start
   activeIndex: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeIndex:'Arrows',
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

   // Customizable Area Start



   handleFollow=()=>{
    return
   }

   handleClick=()=>{
    return
   }

   muteConformation=()=>{
    return
   }

   showReportPost=()=>{
    return 
   }

   handleBlockAccount=()=>{
    return
   }


   handleLikePost=()=>{
    return
   }

   handleSavePost=()=>{
   return 
   }

   handleClickComments=()=>{
    return
   }
 
   handleTabChange=(event:React.ChangeEvent<{}>,newValue:string)=>{
      this.setState({
        activeIndex:newValue
      })
   }



  // Customizable Area End
}
