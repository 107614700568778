Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.otpApiEndpoint="account_block/change_email_or_phone";
exports.apiVerifyOtpMethod ="PATCH"



  exports.welocomeMesage = "Welcome to";
  exports.arrowsText = "ARROWS"; 
  exports.getProgileDataEndPoint = "account_block/accounts/logged_user";  
  exports.picError='please upload jpg/png files';
  exports.phoneRegExpression = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  exports.emailRegex=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  exports.updateEmailEndpoint = "account_block/get_otp";
  exports.otpFieldError='please enter otp';
  exports.enterValidOtp='please enter valid otp';
  exports.otpRegex=/^\d{4}$/;
  
  
// Customizable Area End
