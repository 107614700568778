import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}
export function sendAPIRequest(endpoint: string, params?: RequestParams) {

  const { method = "GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  if (body)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )

  if (headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  return callId
}

export const convertMentionHashtagText = (input: string): string => {
  return input.replace(/@\*(.*?)\*|\@\[(#.*?)\]\(\d+\)/g, (match: string, p1: string, p2: string) => {
    if (p1 !== undefined) {
      return `@${p1}`; // Convert @*mention* to @mention
    } else if (p2 !== undefined) {
      return p2; // Convert @[#hash_tag](5) to #hash_tag
    }
    return match; // Return the original match if no condition is met
  });
}

export const downloadGifFromUrl = async (gifUrl: string, gifName: string) => {
  try {
    const response = await fetch(gifUrl);
    const blob = await response.blob();
    const file = new File([blob], gifName, { type: 'image/gif' });
    return file
  } catch (error) {
    console.error('Error downloading GIF', error);
  }
};

export const removeStorageData = async (key: string) => {
  try {
      localStorage.removeItem(key);
      console.log(`Removed item with key: ${key}`);
  } catch (error) {
      console.error(`Error removing item with key: ${key}`, error);
  }
};

export const highlightText = (body: any, highlights: any) => {
  const combinedHighlights = [...highlights].sort((a, b) => a.indices[0] - b.indices[0]);

  let highlightedBody = '';
  let currentIndex = 0;

  combinedHighlights.forEach(highlight => {
    const start = highlight.indices[0];
    const end = highlight.indices[1];

    highlightedBody += body.substring(currentIndex, start);

    highlightedBody += `<span style="color: ${"#8429Ef"};" >${body.substring(start, end)}</span>`;
    currentIndex = end;
  });

  highlightedBody += body.substring(currentIndex);
  return highlightedBody;
};
