export const lightBg = require("../assets/lightBackGround.png");
export const darkBg = require("../assets/darkBackground.png");
export const prfileFace = require("../assets/face.png");
export const logo = require("../assets/logo.png");
export const verifyLogo = require("../assets/verifyLogo.svg");
export const ckeckedLogo = require("../assets/checkedLogo.svg");
export const mainLogo = require("../assets/image_logo.png");
export const darkArrowIcon = require("../assets/leftIconDark.png");
export const ArrowIcon = require("../assets/leftArrowIcon.png");
export const MenuLight = require("../assets/MenuLight.svg");
export const MenuDark = require("../assets/MenuDark.svg");
export const backCover = require("../assets/backCover.png");
export const avatar = require("../assets/avatar.png");
export const chatIcon = require("../assets/chatIcon.svg");
export const lightChatIcon = require("../assets/lightChatIcon.svg");
export const verifyIcon = require("../assets/verifyIcon.svg");
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const enterprenuarship =require("../assets/enterprenuarship.svg");
export const locationIcon = require("../assets/locationIcon.svg");
export const globalIcon=require("../assets/globalIcon.svg");
export const enterprenuarshipDark =require("../assets/enterprenuarshipDark.svg");
export const locationIconDark = require("../assets/locationIconDark.svg");
export const globalIconDark=require("../assets/globalIconDark.svg");
export const searchDark = require("../assets/searchDark.svg");
export const searchLight = require("../assets/searchLight.svg");
export const callLight = require("../assets/callLight.svg");
export const callDark = require("../assets/callDark.svg");
export const addHighlightstory = require("../assets/addHighlightstory.png");
export const one = require("../assets/1.png");
export const two = require("../assets/2.png");