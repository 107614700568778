import React from "react";
// Customizable Area Start
import { StyleRules, withStyles,createTheme,ThemeProvider } from "@material-ui/core/styles";
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions'
import {userImage,storyImage} from "./assets"
import { Box, Typography } from "@material-ui/core";
import { configJSON } from "./HashtagsController";
import { closeLightBtn } from "../../postcreation/src/assets";

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "#BA05F7",
    },
    info: {
      main: "#222222",
    },
   }
  });

const commonDarkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary: {
        main: "#BA05F7",
      },
      info: {
        main: "#222222",
      },
     }
    });    

const mentionStylesLight = {
  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
      fontFamily: 'Manrope',
      color: '#6B6B6B',
      fontWeight: 700,
      fontSize:'14px'
    },
  },

  '&multiLine': {
    control: {
      minHeight: 20,
      position: 'none !important',
      fontFamily: 'Manrope',
      color: '#6B6B6B',
      fontWeight: 700,
      outline: 'none'
    },
    highlighter: {
      border: 'none',
      padding: 9,
    },
    input: {
      padding: 9,
      border: 'none',
      fontFamily: 'Manrope',
      color: '#6B6B6B',
      fontWeight: 700,
      outline: 'none',
      fontSize:'14px'
    },
  },

  suggestions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    minWidth: '90%',
    borderRadius: '6px',
    height:'300px',
    overflow:'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginTop:'50px',
    backgroundColor: '#FFFFFF',
    list: {
      backgroundColor: '#FFFFFF',
      fontSize: 16,
      borderRadius: '6px',
    },
    item: {
      padding: '5px 15px',
      borderRadius: '6px',
      '&focused': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  width: '100%',
  boxSizing: 'border-box',
  control: {
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Manrope',
  },
}  

const mentionStyles = {
  width: '100%',
  boxSizing: 'border-box',
  control: {
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Manrope',
  },

  '&multiLine': {
    control: {
      minHeight: 20,
      fontFamily: 'Manrope',
      color: 'white',
      fontWeight: 700,
      position: 'none !important',
      outline: 'none'
    },
    highlighter: {
      padding: 9,
      border: 'none',
    },
    input: {
      padding: 9,
      border: 'none',
      fontFamily: 'Manrope',
      color: 'white',
      fontWeight: 700,
      outline: 'none',
      fontSize:'14px'
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
      fontFamily: 'Manrope',
      color: 'white',
      fontWeight: 700,
      fontSize:'14px'
    },

  },

  suggestions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    minWidth: '90%',
    borderRadius: '6px',
    height:'300px',
    overflow:'auto',
    marginTop:'50px',
    backgroundColor: '#222222',
    list: {
      backgroundColor: '#222222',
      fontSize: 16,
      borderRadius: '6px',
    },
    item: {
      padding: '5px 15px',
      borderRadius: '6px',
      '&focused': {
        backgroundColor: '#222222',
      },
    },
  },
}

const highlightText={ 
  color: '#951cf1',
  position: 'relative',
  zIndex: 1,
}
// Customizable Area End

import HashtagsController, {
  Props,
  HashtagAttributes
} from "./HashtagsController.web";

export class Hashtags extends HashtagsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  customSuggestionsRenderer=(user:SuggestionDataItem)=>{
    const userData = user as unknown as { photo: string | null, userName: string | null, followers: number }
    return (<div key={user.id} >
      <div style={customStyles.suggestionContainer}>
        <img src={userData.photo ? userData.photo : storyImage} style={customStyles.profileImage} />
        <div style={customStyles.suggestionText}>
          <Typography style={customStyles.userName}>{user.display}</Typography>
          <Typography style={customStyles.userSuggestion}>{userData.userName ? userData.userName : 'user'} | {userData.followers} followers</Typography>
        </div>
      </div>
    </div>)
  }

  customHashtagRenderer=(user:{id:string|number,display?:string})=>{
    return (<div key={user.id}>
      <div style={customStyles.suggestionContainer}>
        <div style={customStyles.suggestionText}>
          <Typography style={customStyles.userName}>{user.display}</Typography>
          {this.state.trendArray.map((each:{id:string|number,display?:string})=>each.id).includes(user.id)? <Typography style={customStyles.userSuggestion}>{configJSON.trending}</Typography>:''}
        </div>
      </div>
    </div>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, value, onChangeValue, onFocus, showClosePostBtn, onClosePostBtn } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={this.props.checked ? commonLightTheme : commonDarkTheme}>
        <Box className={classes.statusTextContainer}>
            <img src={userImage} className={classes.userImage} />
            <MentionsInput
              data-test-id='textBox'
              placeholder='What’s happening ?'
              style={this.props.checked ? mentionStylesLight : mentionStyles}
              value={value}
              onChange={onChangeValue}
              onFocus={onFocus}
            >
              <Mention
                data-test-id='mention'
                style={highlightText}
                markup={'@*__display__*'}
                trigger="@"
                data={this.fetchUsers}
                renderSuggestion={(user)=>this.customSuggestionsRenderer(user)}
                displayTransform={(id, display) => `@${display}`}
                appendSpaceOnAdd
              />
              <Mention
                data-test-id='tag'
                style={highlightText}
                trigger="#"
                data={this.fetchHastags}
                renderSuggestion={(user)=>this.customHashtagRenderer(user)}
                appendSpaceOnAdd
              />
            </MentionsInput>
            {showClosePostBtn && (
              <img 
                src={closeLightBtn} 
                width={16} 
                height={16} 
                style={{cursor: 'pointer'}}
                onClick={onClosePostBtn}
              />
            )}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles={
  suggestionContainer:
    { display: 'flex', flexDirection: 'row' as 'row' },
  profileImage:
    { marginRight: '10px', height: '44px', width: '44px', borderRadius: '50%' },
  suggestionText:
    { display: 'flex', flexDirection: 'column' as 'column', justifyContent: 'center' },
  userName: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    color: '#fff',
  },
  userSuggestion:
  {
    fontFamily: 'Manrope',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    color: '#fff',
  }
}
const styles: StyleRules = {
  userImage: {
    height: '32px',
    width: '32px',
    marginTop: '4px',
    marginRight: '8px',
  },
  statusTextContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    width: '100%',
    display: 'flex',
  }
}

export default withStyles(styles)(Hashtags);
// Customizable Area End