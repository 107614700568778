import React from 'react'
// Customizable Area Start 
import ProfileController,{Props} from './ProfileController.web';
import {
    Box,
    styled,
    TextField,
    Button,
    InputAdornment,
    Typography,
    Tabs,
    Tab
    } from "@material-ui/core"

import PostHeader from '../../activityfeed/src/PostHeader.web';
import PostBody from '../../activityfeed/src/PostBody.web';
import PostFooter from '../../activityfeed/src/PostFooter.web';
import { highlightText } from '../../../components/src/utils';
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import { ArrowIcon, callDark,callLight,searchDark,searchLight, MenuDark, MenuLight, backCover, darkArrowIcon, avatar, lightChatIcon, chatIcon, verifyIcon, ownerShipIcon, enterprenuarship, enterprenuarshipDark, locationIconDark, locationIcon, globalIconDark, globalIcon, one, two, addHighlightstory } from './assets';

const followers =[
       {
        name:'abc',
        username:'@abc_23'
       },

       {
        name:'xyz',
        username:'@xyz_23'
       }
    
    ]

  const media= [
    {
      "id": 957,
      "url": "https://arrowssocialnetwork1-466346-ruby.b466346.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6e673a5f8f232b46edb4b863004861b0938818af/FELV-cat.jpg",
      "thumbnail_url": null,
      "filename": "FELV-cat.jpg",
      "content_type": "image/jpeg",
      "file_size": 107329
    },
    {
      "id": 958,
      "url": "https://arrowssocialnetwork1-466346-ruby.b466346.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFVGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3e0ff85627b20509ec862fa046ce7363d26f166b/Barack%20Obama%20GIF%20by%20PBS%20News",
      "thumbnail_url": null,
      "filename": "Barack Obama GIF by PBS News",
      "content_type": "image/gif",
      "file_size": 1936951
    },
    {
      "id": 959,
      "url": "https://arrowssocialnetwork1-466346-ruby.b466346.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFlGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--78965d1a8a0368b51b9de2535a1069f720ccfa73/Sad%20Baby%20GIF",
      "thumbnail_url": null,
      "filename": "Sad Baby GIF",
      "content_type": "image/gif",
      "file_size": 1253495
    },
    {
      "id": 960,
      "url": "https://arrowssocialnetwork1-466346-ruby.b466346.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdGNGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b3b5dff994dc39657c314674bca0b2a54fa84914/Oh%20No%20Facepalm%20GIF",
      "thumbnail_url": null,
      "filename": "Oh No Facepalm GIF",
      "content_type": "image/gif",
      "file_size": 2060211
    }
  ]

   
  const HightlightStories = [
       {
       id:1,
       storyTitle:'Award',
       storyData: [],
       stroyImage:one
      },
      {
        id:2,
        storyTitle:'Award',
        storyData: [],
        stroyImage:two
       },
       {
        id:3,
        storyTitle:'Award',
        storyData: [],
        stroyImage:two
       },
       {
        id:4,
        storyTitle:'WAC2024',
        storyData: [],
        stroyImage:two
       },

       {
        id:5,
        storyTitle:'Award',
        storyData: [],
        stroyImage:one
       },
       {
        id:6,
        storyTitle:'Award',
        storyData: [],
        stroyImage:one
       }
   ]
    const feeds = [
      {
        id: "1",
        attributes: {
          body: "This is a sample post mentioning @user and using #hash_tag.",
          body_mentions: [],
          "hash_tags": [
            {
              "hash_tag": "#hash_tag",
              "indices": [
                17,
                26
              ]
            }
          ],
          created_at: "2024-08-28T12:34:56Z",
          post_by: {
            id: 1233,
            full_name: "John Doe",
            user_name: "johndoe",
            is_following: true,
            profile_photo: "https://example.com/profile1.jpg",
            user_ownership: true,
            user_subscription: "premium",
          },
          "media": media,
          media_mentions: [{full_name:"media1"}],
          is_liked: true,
          is_saved: false,
          model_name: "PostModel",
          repost_count: 10,
          total_comments: 5,
          total_likes: 50,
        },
      },
      {
        id: "2",
        attributes: {
          body: "Another example post with different mentions and hashtags #hash_tag .",
          body_mentions: [],
          "hash_tags": [
              {
                "hash_tag": "#hash_tag",
                "indices": [
                  17,
                  26
                ]
              }
            ],
          created_at: "2024-08-28T14:00:00Z",
          post_by: {
            id: 1232,
            full_name: "Jane Smith",
            user_name: "janesmith",
            is_following: false,
            profile_photo: "https://example.com/profile2.jpg",
            user_ownership: false,
            user_subscription: "free",
          },
        "media": media,
        media_mentions: [{full_name:"media1"}],
          is_liked: false,
          is_saved: true,
          model_name: "PostModel",
          repost_count: 20,
          total_comments: 15,
          total_likes: 100,
        },
      },
      // Add more objects as needed
    ];   

// Customizable Area End

export default class Profile  extends ProfileController {
    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
  
    // Customizable Area Start
    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }



    renderTablist=()=>{
      const  { activeIndex } = this.state
      return(
        <Box className={'tabsContainer'}>
        <Tabs
          classes={{ indicator:"indicator" }}
          textColor="primary"
          className='tabs_style'
          value={activeIndex}
          onChange={this.handleTabChange}
          variant="scrollable"
          data-test-id="tabBtn"
        >
          <Tab label="Arrows" value="Arrows" className={'tabstyle'} />
          <Tab label="Replies" value="Replies" className={'tabstyle'} />
          <Tab label="Likes" value="Likes"className={'tabstyle'} />
          <Tab label="Subscriber" value="Subscriber" className={'tabstyle'} />
        </Tabs>
      </Box>
      )
    }

    renderHighlights=()=>{
      return(
        <Box className='hightlightStoryContainer'>
         {HightlightStories.map((item)=>{
          return(
            <Box className='story'>
              <Box className='storyImageContainer'>
              <img className='storyImage'  src={item.stroyImage}/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>{item.storyTitle}</Typography>
              </Box>
           </Box>
          )
         })}

            <Box className='story'>
              <Box className='AddStoryContainer'>
               <img src = {addHighlightstory} height={24} width={24} alt='add_stroy'/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>Add Story</Typography>
              </Box>
           </Box>
          </Box>
      )
    }
    
    renderPostList=()=>{
      const {checked} = this.props
      return(
        <Box className='postList'>
          {
          feeds.map((val) => {
                      const highlights = [...val.attributes.body_mentions, ...val.attributes.hash_tags];
                      const highlightedBody = val.attributes.body && highlightText(val.attributes.body, highlights);
                      const postId = val.id
                      return <Box className={'postDisplay'} key={val.id}>
                        <Box style={{ paddingLeft: '24px' }}>
                          <PostHeader
                            data-testid="postHeader"
                            checked={checked}
                            createdAt={val.attributes.created_at}
                            id={postId}
                            postBy={{
                              id: val.attributes.post_by.id,
                              fullName: val.attributes.post_by?.full_name,
                              userName: val.attributes.post_by?.user_name,
                              isFollowing: val.attributes.post_by?.is_following,
                              profilePhoto: val.attributes.post_by?.profile_photo,
                              userOwnership: val.attributes.post_by.user_ownership,
                              userSubscription: val.attributes.post_by?.user_subscription,
                            }}
                            userInfo={{
                              id: 123,
                            }}
                            onFollow={this.handleFollow}
                            onClickMoreOption={this.handleClick}
                            onMuteConformation={this.muteConformation}
                            onShowReportPost={this.showReportPost}
                            onBlockAccount={this.handleBlockAccount}
                          />
                        </Box>
                        <PostBody
                          highlightedBody={highlightedBody}
                          media={val.attributes.media}
                          mediaMentions={val.attributes.media_mentions}
                        />
                        <Box style={{marginLeft: "67px"}}>
                          <PostFooter
                            data-testid="PostFooter"
                            checked={checked}
                            isLiked={val.attributes.is_liked}
                            isSaved={val.attributes.is_saved}
                            modelName={val.attributes.model_name}
                            postId={postId}
                            repostCount={val.attributes.repost_count}
                            totalComments={val.attributes.total_comments}
                            totalLikes={val.attributes.total_likes}
                            onLikePost={this.handleLikePost}
                            onSavePost={this.handleSavePost}
                            onClickComments={this.handleClickComments}
                          />
                        </Box>
                      </Box>
                    })
          }         
        </Box>
      )
    }  
    // Customizable Area End 
    render() {
      // Customizable Area Start 
      const {checked} = this.props
      // Customizable Area End
      return (
        // Customizable Area Start 
         <NavigationMenu id='' navigation={this.props.navigation} checked={this.props.checked} tabItem='Profile'>
          <BoxContainer>
          <Box className='centerPortionWithTopNavition'>  
          <Box className='TopNavigation'>
                  <Box><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon,ArrowIcon)} /></Box>
                   <Box><img className='topIcon' src={this.conditionRemover(checked,MenuDark,MenuLight)}  /></Box>
                </Box>
            <Box className='centerPortion'>
                <Box className='userProfile'>
                <Box className='BackCover'>
                    <img src={backCover} className='backCoverImage'/>
                </Box>
                <Box className='userProfileBox'>
                    <Box className='userProfileImageContainer'>
                     <Box className='AvatarImage'>
                       <img src={avatar} className='AvatarStyle'/>
                     </Box>
                     <Box className="buttonContainer">
                         <Box>
                          <Button className='gradientBorderButton'>Earnings</Button>
                         </Box>
                         <Box>
                            <Button className="gradientButton" >Create Plan</Button>
                         </Box>
                     </Box>
                    </Box>
                    <Box className='userDetails'>
                    <Box className='userInformation'>
                        <Box className="fullNameStyle">Ralph Edwards</Box>
                        <Box className="usernameStyle">@ralphed</Box>
                        <Box> <img src={verifyIcon} /> </Box>
                        <Box> <img src={ownerShipIcon} /> </Box>
                     </Box>
                     <Box className='location_work_info'>
                        <Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,enterprenuarshipDark,enterprenuarship)}/> </Box>
                           <Box><Typography className='userConnectDetail' >Entrepreneur</Typography> </Box>
                        </Box>
                        <Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,locationIconDark,locationIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>UAE</Typography></Box>
                        </Box>
                        <Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,globalIconDark,globalIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>Ralph.co</Typography></Box>
                        </Box>
                     </Box>
                     <Box className='userDescription'>
                       <Typography className='userDescription-paragraph'>Join your fellow Arrows members in becoming an owner in our social media platform. For the next 30 days you can reserve your interest to purchase shares in Arrows Social, Inc.</Typography>
                     </Box>
                     </Box>

                     <Box className='followers_count_container'>
                       <Box><Typography className='follow_text'>450K Followers</Typography></Box> 
                       <Box><Typography className='follow_text'>357k Following</Typography></Box> 
                       <Box><Typography className='follow_text'>June 2023</Typography></Box> 
                     </Box>
                </Box>
                </Box>
                {this.renderHighlights()}
                {this.renderTablist()}
                {this.renderPostList()}
            </Box>
            </Box>
            <Box className='rightPortion'>
            <Box className={"sideTop"}>
          <TextField
           variant="outlined"
            placeholder="Search"
            className={"searchField"}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.conditionRemover(checked,searchDark,searchLight)} alt="" />
              </InputAdornment>
            ),
          }}
         />

         <img src={this.conditionRemover(checked,callDark,callLight)} alt="" />
         </Box>

          <Box style={{display:"flex",flexDirection:"column",rowGap:"18px"}}>
          <Box className={this.conditionRemover(checked,"subscriptionBoxLight","subscriptionBox")}>
            <Typography className={"name"}>Subscribe to Premium</Typography>
            <Typography className={"subcriptionBody"}>Subscribe to unlock new features and if eligible, receive a share of ads revenue.</Typography>
            <Button className={"continueBtn"}>Continue</Button>
          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D")}}>
             <Typography className={"name"} style={{whiteSpace:"wrap"}}  >Whats Happening</Typography>
             {["","","",""].map((val)=><Box style={{display:"flex",flexDirection:"column",rowGap:"4px"}}>
              <Typography className={"subcriptionBody"}>Trending in India</Typography>
               <Typography className={"name"}>#shardot</Typography>
               <Typography className={"subcriptionBody"}>19.5k posts</Typography>
            </Box>)}
            <Typography className={"showmore"}>Show more</Typography>

          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D"),maxHeight:"299px"}} >
             <Typography className={"name"}>Who to follow</Typography>
   
             <Box className={"followScreen"} >            
          {followers.map((val:{name:string,username:string})=>{
           return  <Box style={{display:"flex",justifyContent:"space-between"}}>
              <Box  style={{display:"flex",gap:"5px",alignItems:"center"}}>            
                 <img
                    src={avatar}
                    alt="img"
                    className={"postImage"}
                  />
              <Box className={"headconatin"}>                  
                  <Box className={"ContentHead"}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"name"}>{val.name}</Typography>
                      <img src={verifyIcon} alt="" />                      
                    </Box>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"userName"}>{val.username} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
            <Button className={"followBtn"}>Follow </Button>
            </Box>             
          })}            
             </Box>   

          </Box>
          </Box> 
            </Box>
          </BoxContainer>
         </NavigationMenu>
        // Customizable Area End
      );
    }
  }

  // Customizable Area Start 
  const BoxContainer = styled(Box)({
    width: "100%",
    display:"flex",
    "& .centerPortionWithTopNavition":{
      display:'flex',
      width:'60%',
      flexDirection:'column',
      "@media (max-width:1000px)": {
        width:'100%'
       }
    },
    "& .TopNavigation":{
      display:'flex',
      flexDirection:'row',
      position:'sticky',
      top:1,
      zIndex:1000,
      maxWidth:" 810px",
      padding:"20px",
      justifyContent:'space-between',
      alignItems:'center',
    },
    "& .centerPortion":{
        height: "calc(100vh - 20px)",
        display: "flex",
        padding:" 0 20px 0px 20px",
        rowGap: "24px",
        scrollbarWidth:'none',
        overflowY:'scroll',
        maxWidth:" 810px",
        flexDirection: "column",
    },
    "& .topIcon":{
        padding:"10px 12px",
       "&:hover":{
        opacity:0.7,
        background:'grey'
       }
    },
    "& .rightPortion":{
        height:"96vh",
        paddingTop:'20px',
        maxWidth:"510px",
        overflow:"scroll" as "scroll",
        width:"40%",
        paddingRight:"98px",
        scrollbarWidth: "none" as "none",
        "&::-webkit-scrollbar": {
          display: "none" as "none",
        },
        "@media (max-width:1000px)": {
          display:"none" as "none"
        },
    },
    "& .sideTop":{
        display:"flex",
        gap:"21px",
        marginBottom:"28px"
      },

    "& .postDisplay": {
        padding: "8px 0px",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px"
    },

    "& .searchField":{
        '& .MuiOutlinedInput-root': {
          borderRadius: "10px", 
          '&.Mui-focused fieldset': {
            border :"1px solid #7A2BF5",       
          },
        },
        width:"80%"
      },  
      

    "& .subscriptionBoxLight":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #222222"
    },
    "& .subscriptionBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #1D1D1D",  
      },
    
    "& .subscrptiontext":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800, 
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent' as "transparent"
    }, 
    "& .subcriptionBody":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
    },
    
    "& .showmore":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },

    "& .name":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800,
        lineHeight:"21px",
        whiteSpace:"nowrap" as "nowrap",
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        }
    },

    "& .whathappiningBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column"
    },

    "& .followScreen":{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"12px",
        height:"270",
        overflowY:"scroll" as "scroll",
        padding: "12px",
      },
    
    "& .continueBtn":{
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        Padding:"6px 20px 6px 20px",
        gap:"7px",
        borderRadius:"10px",
        display:"flex",
        width:"35%",
        color:"white",
        fontSize:"12px",
        fontFamily:"inter",
        fontWeight:500,
        textTransform:"none" as "none",
    },  

    "& .userProfile":{
       width:'100%',
       position:'relative',
    },

    "& .BackCover":{
        width:"100%",
        height:"229px",
    },

    "& .backCoverImage":{
        objectFit:'cover',
        width:"100%",
        height:'100%'
    },

    "& .userProfileBox":{
      backgroundColor: '#D9D9D933',
      marginLeft:"12px",
      background:'transparent',
      marginRight:'12px',
      width:'calc(100% - 24px)',
      border:'1px solid #fffff',
      borderRadius:'14px',
      backdropFilter:'blur(32px)',
      marginTop:'-60px'
    },
    
    "& .AvatarImage":{
      height:62,
      width:62,
      marginTop:'-24px',
      borderRadius:'50%',
      marginLeft:'18px',
      backgroundClip: "padding-box, border-box",
      border :"3px solid transparent",
      backgroundOrigin: "padding-box, border-box",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,

    },

    "& .AvatarStyle":{
        height:62,
        width:62,
        borderRadius:'50%'
      },


    "& .userProfileImageContainer":{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    
    "& .buttonContainer":{
      marginRight:'20px',
      display:'flex',
      gap:'5px'
    },

    "& .Icons":{
        background:'#E9E9E9',
        padding:"2px",
        height:24,
        width:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        borderRadius:"50%"
    },


    "& .IconsLight":{
        background:'#222222',
        padding:"2px",
        height:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        width:24,
        borderRadius:"50%"
    },

    "& .IconImage":{
        height:18,
        width:18
    },

    "& .gradientBorderButton":{
        minWidth:'70px',
        height:'24px',
        padding:"6px 10px",
        textAlign:'center',
        width:'100%',
        borderRadius:'5px',
        textTransform:'capitalize',
        color:'#fff',
        fontFamily:'Manrope',
        fontWeight:700,
        position: 'relative',
        zIndex: 0,
        "&::before":{
          aspectRatio: 1,
          height:24,
          content: "''",
          position: 'absolute',
          zIndex: -1,
          gap:'6px',
          padding: '1px',
          minWidth:'70px',
          borderRadius:'5px',
          width: '100%',
          background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
          webkitMask:  'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          webkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        }
    },

    "& .gradientButton":{
        padding:"6px 10px",
        height:24,
        gap:'6px',
        fontFamily:'Manrope',
        fontSize:12,
        borderRadius:'5px',
        textTransform:'capitalize',
        fontWeight:700,
        color:'#fff',
        minWidth:'70px',
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)'
    },

    "& .userInformation":{
        display:'flex',
        position:'unset',
        alignItems:'center',
        justifyContent:'left',
        height:"unset",
        gap:'5px',
        paddingTop:'5px',
        margin:'unset',
    },

    "& .fullNameStyle":{
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "left",
    },

    "& .usernameStyle":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: "left",
    },

    "& .userDescription-paragraph":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "left",
    },

    '& .userDetails':{
     padding:'0px 20px',
     display:'flex',
     flexDirection:'column',
     gap:'5px'
    },

    "& .location_work_info":{
        margin:'5px 0px',
        display:'flex',
        gap:'20px'
    },

    "& .info_container":{
        display:'flex',
        gap:'5px',
        alignItems:'center',
        justifyContent:'center'
    },

    "& .userConnectDetail":{
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: 500,
        textAlign: 'left',
    },

    "& .followers_count_container":{
        display:'flex',
        padding:20,
        gap:'49px'
    },

    "& .follow_text":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: 'left',
    },

    "& .postImage":{
        height:"35px",
        width:"35px",
        borderRadius:"50%",
        objectFit:"cover" as "cover"
    },

    "& .ContentHead":{
        display:"flex",
        flexDirection:"column" as "column",
        gap:"5px",
    },

    "& .headconatin":{
        display:"flex",
        width:"100%",
        justifyContent:"space-between"
    },

    "& .followBtn":{
        width:"65px",
        height:"25px",
        borderRadius:"10px",
        padding:"4px 8px 4px 8px",
        gap:"7px",
        fontSize:"12px",
        fontFamily:"Manrope",
        color:"white",
        fontWeight:500,
        textTransform:"capitalize" as "capitalize",
        background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },

      '& .postList':{
        width:"100%"
      },
   
      "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        minWidth:'unset !important',
        width:'25%',
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid gray",
        '&.Mui-selected': {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent' as "transparent",
        },
       
        '@media(max-width:960px)':{
          width:'50%'
        },
      },
      "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
      },

      "& .tabsContainer": {
        height: "35px",
        width:'100%',
      },

      "& .hightlightStoryContainer":{
        margin:12,
        display:'flex',
        flexDirection:'row',
         minHeight:84,
         overflowX:'scroll',
         overflowY:'hidden',
        scrollbarWidth:'none',
        gap:20
      },

      "& .story":{
        display:'flex',
        flexDirection:'column',
        gap:5
      },

      "& .storyImage":{
        border: '2px solid #6B6B6B',
        width: '64px',
        height: '64px',
        borderRadius: '50px'
      },

      "& .AddStoryContainer":{
        width: '64px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '64px',
        borderRadius: '50px',
        border: '2px solid #fff',
        background:'#6B6B6B'
      },

      "& .story_Text":{
        fontFamily: 'Manrope',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '12px',
        textAlign: 'center',
      }
  })
  // Customizable Area End
  