// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { EmojiData } from "emoji-mart";
import { sendAPIRequest } from "../../../components/src/utils";
import React from "react";

export const configJSON = require("./config");

interface HashTag {
  hash_tag: string;
  indices: number[];
}

interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: number[];
}

interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}

interface CommentBy {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  admin_verification: boolean;
  profile_photo: string | null;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

interface MentionedAccount extends CommentBy {}

interface CommentAttributes {
  id: number;
  body: string;
  created_at: string;
  updated_at: string;
  is_reply: boolean;
  is_liked: boolean;
  total_likes: number;
  total_replies: number;
  model_name: string;
  hash_tags: HashTag[];
  body_mentions: BodyMention[];
  media: Media[];
  comment_by: CommentBy;
  mentioned_accounts: MentionedAccount[];
}

export interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}

export interface Props {
  classes?: any
  checked: boolean
  open: boolean
  onClose: () => void
  postData: any
  userInfo: {
    id: number
  },
  onFollow: (id: number, type: string) => void,
  onClickMoreOption: (activeUser: any, postId: string) => void
  onMuteConformation: () => void
  onBlockAccount: (id: number) => void
  onShowReportPost: () => void
  onLikePost: (id: string, type: string, likeableType: string) => void
  onSavePost: (id: string, type: string) => void
  onUpdateNumberOfComments: () => void
}

interface S {
  token: string
  emojiAnchorEl: null | HTMLElement
  commentValue: string
  loading: boolean
  comments: CommentData[]
  paginationDetails: PaginationDetails
  fetchPage: number
  postBodyTextHeight: number
  likedCommentId: string
}

interface SS {
  id: any;
}

export default class CommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCommentListId: string = ''
  likeCommentId: string = ''
  submitCommentId: string = ''

  scrollCommentsRef = React.createRef<HTMLDivElement>()
  postBodyTextRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: '',
      emojiAnchorEl: null,
      commentValue: '',
      loading: true,
      comments: [],
      paginationDetails: {
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0,
      },
      fetchPage: 1,
      postBodyTextHeight: 0,
      likedCommentId: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken') || ''
    this.getListComments(token)
    this.setState({ token })
    setTimeout(this.updateBodyTextHeight, 0)
    window.addEventListener('resize', this.updateBodyTextHeight);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateBodyTextHeight);
  }

  updateBodyTextHeight = () => {
    if (this.postBodyTextRef.current) {
      const height = this.postBodyTextRef.current.offsetHeight;
      this.setState({ postBodyTextHeight: height });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getCommentListId]: this.handleGetCommentListResponse,
      [this.likeCommentId]: this.handleLikeCommentResponse,
      [this.submitCommentId]: this.handleSubmitCommentResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleSubmitCommentResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const updateComments = [...this.state.comments]
      updateComments.unshift(responseJson?.data)
      this.setState({comments: updateComments})
      this.props.onUpdateNumberOfComments()
    }
  }

  handleLikeCommentResponse = (responseJson: any) => {
    if (responseJson.messages[0] === "Liked Successfully" || responseJson.messages[0] === "Unliked Successfully") {
      const isLike = responseJson.messages[0] === "Liked Successfully"
      const cloneComment = [...this.state.comments]
      const updateComent: CommentData[] = cloneComment.map(comment => {
        if (comment.id === this.state.likedCommentId) {
          return {
            ...comment, 
            attributes: {
              ...comment.attributes,
              is_liked: isLike ? true : false,
              total_likes: isLike ? comment.attributes.total_likes + 1 : comment.attributes.total_likes - 1,
            }
          }
        } else {
          return comment
        }
      })
      this.setState({comments: updateComent})
    }
  }

  handleGetCommentListResponse = (responseJson: any) => {
    const comments: CommentData[] = responseJson?.data;
    const pagination: PaginationDetails = responseJson?.pagination_details
    if (comments) {
      this.setState({
        comments: [...this.state.comments,...comments], 
        loading: false,
        fetchPage: this.state.fetchPage + 1,
        paginationDetails: pagination,
      })
    }
  }

  getListComments = (token: string) => {
    this.getCommentListId = sendAPIRequest(
      `bx_block_comments/comments?commentable_type=post&commentable_id=${this.props.postData.id}&page=${this.state.fetchPage}&per_page=10&sort_order=asc`,
      {
        method: 'GET',
        headers: {
          token: token,
        },
      }
    )
  }

  likeComment = (commentId: string, isLike: boolean) => {
    this.setState({likedCommentId: commentId})
    const httpBody = {
      likeable_id: commentId,
      likeable_type:'comment'   
    }
    this.likeCommentId = sendAPIRequest(
      `bx_block_like/likes`,
      {
        method: isLike ? 'POST' : 'DELETE',
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body: httpBody,
      }
    )
  }

  submitComment = () => {
    const commentBody = {
      "commentable_type": "post",
      "commentable_id": this.props.postData.id,
      "body": this.state.commentValue
    }
    this.submitCommentId = sendAPIRequest(
      `bx_block_comments/comments`,
      {
        method: 'POST',
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body: commentBody,
      }
    )
    this.setState({commentValue: ''})
  }

  handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({commentValue: event.target.value})
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelectEmoji = (emoji: EmojiData) => {
    this.setState({commentValue: this.state.commentValue + (emoji as any).native});
  };

  handleClickCommentBtn = () => {}
}

// Customizable Area End